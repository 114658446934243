// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("/opt/build/repo/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-author-js": () => import("/opt/build/repo/src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-tag-js": () => import("/opt/build/repo/src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-subscribe-index-js": () => import("/opt/build/repo/src/pages/subscribe/index.js" /* webpackChunkName: "component---src-pages-subscribe-index-js" */),
  "component---src-pages-subscribe-thank-you-js": () => import("/opt/build/repo/src/pages/subscribe/thank-you.js" /* webpackChunkName: "component---src-pages-subscribe-thank-you-js" */),
  "component---src-pages-tags-index-js": () => import("/opt/build/repo/src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

